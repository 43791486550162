import React from "react";
import Link from "next/link";

const CallToActions = () => {
  const calltoActionContent = {
    title: "Deal with your clients & employees with crypto payments.",
    text: `Our Jobs is a borderless platform that uses the blockchain to unleash the world's productivity. View performance reviews as saved on the blockchain before hiring new recruits to ensure you hire who you can trust.`,
    btnText: "Connect your Wallet",
  };
  return (
    <div className="relative overflow-hidden rounded-2.5xl px-16 py-24 lg:px-24 ">
      <picture className="pointer-events-none absolute inset-0 -z-10 h-[150%] dark:hidden">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-0 -z-10 hidden h-[150%] dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>
      <img
        src="/images/crypto-trading/crypto_trading_cta_icons.png"
        alt=""
        className="pointer-events-none absolute top-1/2 right-1/4 -z-10 -translate-y-1/2"
        loading="lazy"
      />
      {/* End images */}

      <div className="lg:flex lg:justify-between ">
        <div className="mb-6 max-w-lg lg:mb-0">
          <h2 className="mb-5 font-display text-3xl text-jacarta-700 dark:text-white">
            {calltoActionContent.title}
          </h2>
          <p className="text-lg leading-normal dark:text-jacarta-300">
            {calltoActionContent.text}
          </p>
          <Link className="shrink-0" href="/login">
          <a
          className="mt-4 inline-block self-center rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
        >
          {calltoActionContent.btnText}
        </a>
          </Link>
         
        </div>
        <img src="/images/crypto-consultant/cta_illustration.png" alt="" />
       
      </div>
    </div>
  );
};

export default CallToActions;
